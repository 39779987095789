import { IconCircleCheckFilled } from "@tabler/icons-react";
import React from "react";
import "./PLanDetails.css";

const PlanDetails = () => {
  return (
    <>
      <div className="container" style={{ marginTop: "70px" }}>
        <div className="pst-space row  justify-content-center ">
          <div
            className="price_section col-lg-4 mb-xs-3 mb-sm-4 mb-lg-0"
            style={{ margin: "0" }}
          >
            <div className="pricing-card">
              <h3 style={{ fontWeight: "500" }}>Basic Plan</h3>
              <div className="price">
                <span className="currency fw-bold">₹</span>
                <span className="amount">10,000</span>
                <span className="time">/ month</span>
              </div>
              <p className="heading fs-5 mt-1">Featured Included:</p>

              <div className="pricing-icons-section d-flex gap-2 align-items-center ">
                <div className="wrap">
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">
                  All Question types except video
                </p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  Unlimited Test Creations
                </p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  Candidates - 300 test submissions/month
                </p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  Random Image Captures
                </p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3"> Users 5</p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  Data Retension 1 Year
                </p>
              </div>

              <a href="#" className="btn btn-primary w-100 mt-3 fs-5 fw-bold">
                Downgrade to Basic
              </a>
            </div>
          </div>
          <div
            className="price_section col-lg-4  mb-xs-3 mb-sm-4 mb-lg-0"
            style={{ margin: "0" }}
          >
            <div className="pricing-card-popular">
              <div className="pricing-popular-badge">Selected</div>
              <h3>Standard Plan</h3>
              <div className="price">
                <span className="currency fw-bold">₹</span>
                <span className="amount">10,000</span>
                <span className="time">/ month</span>
              </div>
              <p className="heading fs-5 mt-1">Featured Included:</p>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="white" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">Video Feature</p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="white" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">Unlimited Users</p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="white" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  2000 Candidates/month
                </p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="white" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">
                  Question Repository Access
                </p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="white" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">
                  Data Retension 3 Years
                </p>
              </div>

              <a href="#" className="btn btn-light mt-3 w-100 fw-bold fs-5">
                Currently Active
              </a>
            </div>
          </div>
          <div className="price_section col-lg-4" style={{ margin: "0" }}>
            <div className="pricing-card">
              <h3>Enterprise Plan</h3>
              <div className="price">
                <span className="currency fw-bold">₹</span>
                <span className="amount">10,000</span>
                <span className="time">/ month</span>
              </div>

              <p className="heading fs-5 mt-1">Featured Included:</p>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p  fs-5 mt-3">Contact Support</p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">Video Feature</p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">Unlimited Users</p>
              </div>

              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>

                <p className="pricing-features-p fs-5 mt-3">
                  Unlimited Candidates/month
                </p>
              </div>
              <div className="pricing-icons-section d-flex gap-2 align-items-center">
                <div className="wrap" style={{ width: "35px" }}>
                  <IconCircleCheckFilled color="#321FDB" size={35} stroke={2} />
                </div>
                <p className="pricing-features-p fs-5 mt-3">
                  Question Repository Access
                </p>
              </div>

              <a href="#" className="btn btn-primary mt-3 w-100 fw-bold fs-5">
                Upgrade to Enterprise
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanDetails;
